import styled from 'styled-components';

const PageWrapper = styled.div`
  margin: 5rem 0;
  .page-meta {
    padding-top: 5rem;
    text-align: center;
    font-size: 0.85rem;
    line-height: 25px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.labelColor};
    margin-bottom: 2rem;
    margin: 0 5rem;
  }
  .page-info {
    text-align: center;
    margin: 0 7rem;
    margin-bottom: 2rem;
    font-size: 38px;
    line-height: 72px;
    font-weight: 700;
    @media only screen and (max-width: 990px) {
      margin: 0 5rem;
    }
    @media only screen and (max-width: 768px) {
      font-size: 30px;
      line-height: 62px;
      margin: 0 2rem;
    }
    @media only screen and (max-width: 480px) {
      font-size: 24px;
      line-height: 50px;
      margin: 0 0.5rem;
    }
  }
  .page-cover {
    justify-content: center;
    display: flex;
  }
  .page-body {
    margin: 0 10rem;
    @media only screen and (max-width: 990px) {
      margin: 0 5rem;
    }
    @media only screen and (max-width: 768px) {
      margin: 0 3rem;
    }
    @media only screen and (max-width: 480px) {
      margin: 0 0.5rem;
    }
    img {
      width: 100%;
    }
    h1,
    h2,
    h3,
    h4 {
      padding-top: 16px;
      margin-bottom: 30px;
      color: ${({ theme }) => theme.colors.headingColor};
      font-weight: 500;
    }
    p {
      font-size: 20px;
      line-height: 30px;
      font-weight: 300;
      margin-bottom: 30px;
      color: ${({ theme }) => theme.colors.textColor};
      @media only screen and (max-width: 480px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    ul,
    ol {
      font-size: 20px;
      line-height: 30px;
      font-weight: 300;
      margin-bottom: 30px;
      color: ${({ theme }) => theme.colors.textColor};
      @media only screen and (max-width: 480px) {
        font-size: 18px;
        line-height: 24px;
      }
      li {
        list-style-type: circle;
        a {
          font-weight: 400;
          color: ${({ theme }) => theme.colors.headingColor};
        }
      }
    }
    ol {
      li {
        list-style-type: decimal;
      }
    }
    .text-center {
      text-align: center;
    }
    .pt-40 {
      padding-top: 2.5rem;
    }
    .pb-30 {
      padding-bottom: 2rem;
    }
    .font-weight-900 {
      font-weight: 900;
    }
    blockquote {
      color: ${({ theme }) => theme.colors.black};
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      margin: 0;
      margin-bottom: 30px;
      position: relative;
      z-index: 1;
      @media only screen and (max-width: 480px) {
        font-size: 24px;
        line-height: 36px;
      }
      .quotations {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 5rem;
        color: ${({ theme }) => theme.colors.headingColor};
        opacity: 0.5;
        z-index: -1;
      }
    }
    hr {
      width: 75%;
    }
  }
`;

export default PageWrapper;
